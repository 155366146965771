exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---node-modules-gatsby-theme-academic-src-pages-404-index-jsx": () => import("./../../../node_modules/gatsby-theme-academic/src/pages/404/index.jsx" /* webpackChunkName: "component---node-modules-gatsby-theme-academic-src-pages-404-index-jsx" */),
  "component---node-modules-gatsby-theme-academic-src-pages-contact-index-jsx": () => import("./../../../node_modules/gatsby-theme-academic/src/pages/contact/index.jsx" /* webpackChunkName: "component---node-modules-gatsby-theme-academic-src-pages-contact-index-jsx" */),
  "component---node-modules-gatsby-theme-academic-src-pages-experience-index-jsx": () => import("./../../../node_modules/gatsby-theme-academic/src/pages/experience/index.jsx" /* webpackChunkName: "component---node-modules-gatsby-theme-academic-src-pages-experience-index-jsx" */),
  "component---node-modules-gatsby-theme-academic-src-pages-index-jsx": () => import("./../../../node_modules/gatsby-theme-academic/src/pages/index.jsx" /* webpackChunkName: "component---node-modules-gatsby-theme-academic-src-pages-index-jsx" */),
  "component---node-modules-gatsby-theme-academic-src-pages-posts-index-jsx": () => import("./../../../node_modules/gatsby-theme-academic/src/pages/posts/index.jsx" /* webpackChunkName: "component---node-modules-gatsby-theme-academic-src-pages-posts-index-jsx" */),
  "component---node-modules-gatsby-theme-academic-src-pages-research-index-jsx": () => import("./../../../node_modules/gatsby-theme-academic/src/pages/research/index.jsx" /* webpackChunkName: "component---node-modules-gatsby-theme-academic-src-pages-research-index-jsx" */),
  "component---node-modules-gatsby-theme-academic-src-pages-resume-jsx": () => import("./../../../node_modules/gatsby-theme-academic/src/pages/resume.jsx" /* webpackChunkName: "component---node-modules-gatsby-theme-academic-src-pages-resume-jsx" */),
  "component---node-modules-gatsby-theme-academic-src-pages-tags-index-jsx": () => import("./../../../node_modules/gatsby-theme-academic/src/pages/tags/index.jsx" /* webpackChunkName: "component---node-modules-gatsby-theme-academic-src-pages-tags-index-jsx" */),
  "component---node-modules-gatsby-theme-academic-src-pages-wakatime-index-jsx": () => import("./../../../node_modules/gatsby-theme-academic/src/pages/wakatime/index.jsx" /* webpackChunkName: "component---node-modules-gatsby-theme-academic-src-pages-wakatime-index-jsx" */),
  "component---node-modules-gatsby-theme-academic-src-templates-post-post-jsx-content-file-path-content-posts-bertrand-paradox-index-mdx": () => import("./../../../node_modules/gatsby-theme-academic/src/templates/post/post.jsx?__contentFilePath=/vercel/path0/content/posts/BertrandParadox/index.mdx" /* webpackChunkName: "component---node-modules-gatsby-theme-academic-src-templates-post-post-jsx-content-file-path-content-posts-bertrand-paradox-index-mdx" */),
  "component---node-modules-gatsby-theme-academic-src-templates-post-post-jsx-content-file-path-content-posts-coloring-index-mdx": () => import("./../../../node_modules/gatsby-theme-academic/src/templates/post/post.jsx?__contentFilePath=/vercel/path0/content/posts/Coloring/index.mdx" /* webpackChunkName: "component---node-modules-gatsby-theme-academic-src-templates-post-post-jsx-content-file-path-content-posts-coloring-index-mdx" */),
  "component---node-modules-gatsby-theme-academic-src-templates-post-post-jsx-content-file-path-content-posts-fair-redistricting-commissions-index-mdx": () => import("./../../../node_modules/gatsby-theme-academic/src/templates/post/post.jsx?__contentFilePath=/vercel/path0/content/posts/Fair-Redistricting-Commissions/index.mdx" /* webpackChunkName: "component---node-modules-gatsby-theme-academic-src-templates-post-post-jsx-content-file-path-content-posts-fair-redistricting-commissions-index-mdx" */),
  "component---node-modules-gatsby-theme-academic-src-templates-post-post-jsx-content-file-path-content-posts-hello-world-index-mdx": () => import("./../../../node_modules/gatsby-theme-academic/src/templates/post/post.jsx?__contentFilePath=/vercel/path0/content/posts/HelloWorld/index.mdx" /* webpackChunkName: "component---node-modules-gatsby-theme-academic-src-templates-post-post-jsx-content-file-path-content-posts-hello-world-index-mdx" */),
  "component---node-modules-gatsby-theme-academic-src-templates-post-post-jsx-content-file-path-content-posts-how-2-type-fast-index-mdx": () => import("./../../../node_modules/gatsby-theme-academic/src/templates/post/post.jsx?__contentFilePath=/vercel/path0/content/posts/How2TypeFast/index.mdx" /* webpackChunkName: "component---node-modules-gatsby-theme-academic-src-templates-post-post-jsx-content-file-path-content-posts-how-2-type-fast-index-mdx" */),
  "component---node-modules-gatsby-theme-academic-src-templates-post-post-jsx-content-file-path-content-posts-marta-index-mdx": () => import("./../../../node_modules/gatsby-theme-academic/src/templates/post/post.jsx?__contentFilePath=/vercel/path0/content/posts/Marta/index.mdx" /* webpackChunkName: "component---node-modules-gatsby-theme-academic-src-templates-post-post-jsx-content-file-path-content-posts-marta-index-mdx" */),
  "component---node-modules-gatsby-theme-academic-src-templates-post-post-jsx-content-file-path-content-posts-notes-index-mdx": () => import("./../../../node_modules/gatsby-theme-academic/src/templates/post/post.jsx?__contentFilePath=/vercel/path0/content/posts/Notes/index.mdx" /* webpackChunkName: "component---node-modules-gatsby-theme-academic-src-templates-post-post-jsx-content-file-path-content-posts-notes-index-mdx" */),
  "component---node-modules-gatsby-theme-academic-src-templates-post-post-jsx-content-file-path-content-posts-vs-code-la-te-x-inkscape-index-mdx": () => import("./../../../node_modules/gatsby-theme-academic/src/templates/post/post.jsx?__contentFilePath=/vercel/path0/content/posts/VSCode-LaTeX-Inkscape/index.mdx" /* webpackChunkName: "component---node-modules-gatsby-theme-academic-src-templates-post-post-jsx-content-file-path-content-posts-vs-code-la-te-x-inkscape-index-mdx" */),
  "component---node-modules-gatsby-theme-academic-src-templates-post-post-jsx-content-file-path-content-research-adversarial-attack-on-data-attribution-index-mdx": () => import("./../../../node_modules/gatsby-theme-academic/src/templates/post/post.jsx?__contentFilePath=/vercel/path0/content/research/Adversarial-Attack-on-Data-Attribution/index.mdx" /* webpackChunkName: "component---node-modules-gatsby-theme-academic-src-templates-post-post-jsx-content-file-path-content-research-adversarial-attack-on-data-attribution-index-mdx" */),
  "component---node-modules-gatsby-theme-academic-src-templates-post-post-jsx-content-file-path-content-research-dattri-index-mdx": () => import("./../../../node_modules/gatsby-theme-academic/src/templates/post/post.jsx?__contentFilePath=/vercel/path0/content/research/dattri/index.mdx" /* webpackChunkName: "component---node-modules-gatsby-theme-academic-src-templates-post-post-jsx-content-file-path-content-research-dattri-index-mdx" */),
  "component---node-modules-gatsby-theme-academic-src-templates-post-post-jsx-content-file-path-content-research-miss-index-mdx": () => import("./../../../node_modules/gatsby-theme-academic/src/templates/post/post.jsx?__contentFilePath=/vercel/path0/content/research/MISS/index.mdx" /* webpackChunkName: "component---node-modules-gatsby-theme-academic-src-templates-post-post-jsx-content-file-path-content-research-miss-index-mdx" */),
  "component---node-modules-gatsby-theme-academic-src-templates-post-post-jsx-content-file-path-content-research-pseudo-non-linear-data-augmentation-via-energy-minimization-index-mdx": () => import("./../../../node_modules/gatsby-theme-academic/src/templates/post/post.jsx?__contentFilePath=/vercel/path0/content/research/Pseudo-Non-Linear-Data-Augmentation-via-Energy-Minimization/index.mdx" /* webpackChunkName: "component---node-modules-gatsby-theme-academic-src-templates-post-post-jsx-content-file-path-content-research-pseudo-non-linear-data-augmentation-via-energy-minimization-index-mdx" */),
  "component---node-modules-gatsby-theme-academic-src-templates-post-post-jsx-content-file-path-content-research-travel-the-same-path-index-mdx": () => import("./../../../node_modules/gatsby-theme-academic/src/templates/post/post.jsx?__contentFilePath=/vercel/path0/content/research/Travel-the-Same-Path/index.mdx" /* webpackChunkName: "component---node-modules-gatsby-theme-academic-src-templates-post-post-jsx-content-file-path-content-research-travel-the-same-path-index-mdx" */),
  "component---node-modules-gatsby-theme-academic-src-templates-post-post-jsx-content-file-path-content-research-unlearning-sample-inference-game-index-mdx": () => import("./../../../node_modules/gatsby-theme-academic/src/templates/post/post.jsx?__contentFilePath=/vercel/path0/content/research/Unlearning-Sample-Inference-Game/index.mdx" /* webpackChunkName: "component---node-modules-gatsby-theme-academic-src-templates-post-post-jsx-content-file-path-content-research-unlearning-sample-inference-game-index-mdx" */),
  "component---node-modules-gatsby-theme-academic-src-templates-tags-index-jsx": () => import("./../../../node_modules/gatsby-theme-academic/src/templates/tags/index.jsx" /* webpackChunkName: "component---node-modules-gatsby-theme-academic-src-templates-tags-index-jsx" */),
  "component---node-modules-gatsby-theme-academic-src-templates-tags-index-jsx-content-file-path-content-tags-gatsby-index-md": () => import("./../../../node_modules/gatsby-theme-academic/src/templates/tags/index.jsx?__contentFilePath=/vercel/path0/content/tags/gatsby/index.md" /* webpackChunkName: "component---node-modules-gatsby-theme-academic-src-templates-tags-index-jsx-content-file-path-content-tags-gatsby-index-md" */)
}

